import React, { useState } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ReflectVideo from "../../assets/reflect.mp4"
import AlsoHelpsMenu from "../../components/alsoHelpsMenu"
import { playVideo, playVideoTitle, bridgeAction } from "../../components/utils"
import VideoWrapper from "../../components/videoWrapper"

const Page = ({ data }) => {
  const [playingVideo, setPlayingVideo] = useState(false)

  // TODO seo
  return (
    <Layout containerId="home">
      <Seo title="Mente" />

      <div className="feature-sections">
        <section className="full-width" style={{ marginBottom: "100px" }}>
          <div className="grid full-width-grid">
            <div className="full-width-grid" style={{ textAlign: "center" }}>
              <h2 className="feature-group-title">Work-life balance</h2>
              <p className="feature-group-decription inapp-invisible">
                Gjør det til en vane å reflektere over et bredt utvalg spørsmål,
                for å få bedre perspektiv og balanse i livet. Samtidig kan du
                enkelt gå fra tanker til handling, ved å gjøre om svarene dine
                til oppgaver.
              </p>

              <div id="feature-cta-wrapper">
                <button
                  className="btn-cta"
                  onClick={() => playVideo("demo", "feature-cta-wrapper")}
                  style={{ marginBottom: "0.5em" }}
                >
                  {playVideoTitle(playingVideo, "1. Se hvordan")}
                </button>
                <br />
                <button
                  className="btn-cta"
                  onClick={() => bridgeAction("reflect")}
                  style={{ marginBottom: "0.5em" }}
                >
                  2. Prøv selv
                </button>
              </div>

              <div style={{ marginTop: "2rem" }}>
                <VideoWrapper
                  id="video_demo"
                  video={ReflectVideo}
                  videoEvent={setPlayingVideo}
                />
              </div>
            </div>
          </div>
        </section>

        <AlsoHelpsMenu except="reflection" />
      </div>
    </Layout>
  )
}

export default Page
